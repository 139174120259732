
import 'react-loading-skeleton/dist/skeleton.css'
import "react-datepicker/dist/react-datepicker.css";
import ReactEcharts from "echarts-for-react"; 







const PieChart = ({items}) => {
    
    // const option = {
    //     series: [
    //       {
    //         type: 'pie',
    //         data: items
    //       }
    //     ]
    //   };

    const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'center',
          left: 'left',
          width: '10',
          type: 'scroll',
          orient: 'vertical',
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   right: 10,
        //   top: 20,
        //   bottom: 20,
        //   //data: data.legendData
        // },
        series: [
          {
            name: 'Atividades',
            type: 'pie',
            radius: ['40%', '70%'],
            //radius: '55%',
            center: ['65%', '50%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: 20,
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
              show: false
            },
            //color: ['#00b04f', '#ffbf00', '#ff0000'],
            data: items
          }
        ]
      };

    
      return (
        <ReactEcharts option={option} style={{height: '400px', width: '100%'}}
        className='echarts-pie'
        //theme='my_theme' 
        />
      );
      


        
}


export default function CatPie({activities, taxonomy = 'activity_category', countTime = false, title = 'Categorias', colorPallete = false }) {

    let soma = 0;
    let taxonomy_items = {};

    if(activities){                        
        activities.map( (activity, i) => {

          
          let current_taxonomy_name = activity[taxonomy]?.name;
          
          if(countTime){
            
              let activity_duration = new Date(activity.activity_registered_check_out_time) - new Date(activity.activity_registered_check_in_time);  
              
              if(taxonomy_items[current_taxonomy_name]){
                taxonomy_items[current_taxonomy_name] += activity_duration / 1000 / 60 / 60; 
              }else{
                taxonomy_items[current_taxonomy_name] = activity_duration / 1000 / 60 / 60;
              }

              if(activity_duration){
                soma += Math.fround(activity_duration);
              }else{
                console.log('NaN:', activity.post_title);
                //throw new Error('NaN:', activity.post_title);
              }

          }else{

              if(taxonomy_items[current_taxonomy_name]){
                taxonomy_items[current_taxonomy_name] += 1;
              }else{
                  taxonomy_items[current_taxonomy_name] = 1;
              }
          }
          
        })

        soma = countTime ? soma / 1000 / 60 / 60 : activities.length;
    }


    let data = [];
    
    console.log('taxonomy_items', taxonomy_items);
    console.log('colorPallete', colorPallete);

    Object.keys(taxonomy_items).map( (key, i) => {
        data.push(
            {
                value: taxonomy_items[key].toFixed(2),
                name: key,
                itemStyle: (colorPallete && taxonomy == 'professional_id') ? {color: colorPallete[key]} : '',
            });
    })

    data.sort(function(a, b){      
      return b.value - a.value;
    });


    return (

      <div className="col pb-4">
        <div className="card align-items-center justify-content-center p-5">
            
            {
              countTime 
              ? <h2 className="title">{Math.floor(soma).toLocaleString()}h<span className="minutes">{Math.floor( (soma % 1).toFixed(4) * 60 )}min</span></h2>
              : <h2 className="title"> {soma} <span>Atividades</span> </h2>
            }
            
            <h3 className='sub-title'> {Object.keys(taxonomy_items).length} {title} </h3>

            <PieChart items={data} />
        </div>
      </div>
    )


}
