import { useState, useEffect } from "react";
import Swal from "sweetalert2";


//TODO: Tirar Swal daqui
function useTerms() {
  
  const [terms, setTerms] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [activityStatus, setActivityStatus] = useState([]);


  const [isLoading, setIsLoading] = useState(false);

  let token = btoa("adminbg" + ":" + "Fj55t5rhKKg%%M3bQ5jOmf");
  const headers = { Authorization: "Basic " + token};


  async function getTerms() {

    setIsLoading(true);

    try {
        const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/all_tax_terms", {
          method: "GET",
          headers: headers, 
        })

        if(response.ok){
          let data = await response.json();
          console.log('A solicitação TERMS foi feita.');
          console.log(data);      

          setTerms(data);
          setEmpresas(data['client']);
          setCategorias(data['activity_category']);
          setActivityStatus(data['activity_status']);
          setIsLoading(false);

        }else{
          throw new Error('Houve um problema no fetch: (response.ok = false)');
        }
    } catch (error) {
      console.log('Erro no getActivities()');
      console.log(error);
      setIsLoading(false);

    }

  }


  function formDataToUrlEncoded(formData) {
    const urlSearchParams = new URLSearchParams();
  
    for (const pair of formData.entries()) {
      urlSearchParams.append(pair[0], pair[1]);
    }
  
    return urlSearchParams.toString();
  }

        //   async function putActivity(activitydata) {

        //     if(!activitydata) return;

        //     try {

        //         console.log('activitydata');
        //         console.log(Object.fromEntries(activitydata) );
        //         //console.log(JSON.stringify( Object.fromEntries(activitydata) ));

        //         const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity", {
        //           method: "PUT",
        //           headers: { 
        //             'Authorization': "Basic " + token,
        //             'Content-Type': 'application/x-www-form-urlencoded'
        //           },
                
        //           //body: JSON.stringify( Object.fromEntries(activitydata) ),
        //           body: formDataToUrlEncoded(activitydata),
                
        //         })

        //         let responseData = await response.json();
        //         console.log('A solicitação putActivity foi feita.');
                
        //         if(response.ok){
        //           console.log('response ok');
        //           console.log(responseData);
        //           Swal.fire({
        //             title: 'Sucesso',
        //             text: responseData.message,
        //             icon: 'success',
        //             confirmButtonText: 'Ok'
        //           }).then( () => {
        //             updateActivitiesList()
        //           })
        //         }else{
        //           console.log('response NOT ok');
        //           console.log(responseData);

        //           Swal.fire({
        //             title: 'Erro',
        //             text: responseData.message,
        //             icon: 'error',
        //             confirmButtonText: 'Ok'
        //           })
        //           // .then( () => {
        //           //     navigate('../');
        //           //     navigate(0);
        //           // })

        //           throw new Error('Houve um problema no fetch: (response.ok = false)');
        //         }
        //     } catch (error) {
        //       console.log('Erro no PostActivity()');
            
        //       console.log(error);
        //       setIsLoading(false);

        //     }

        //   }


  useEffect(() => {

    getTerms();
    
  }, []);



  const updateTermsList = async () => {
    console.log('updating Terms...');
    await getTerms();
  }




  return {
        terms, 
        empresas,
        categorias,
        updateTermsList, 
        activityStatus,
        //putActivity, 
        isLoading
    };

}


export default useTerms;