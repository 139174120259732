
function ErrorPage() {
  //const [count, setCount] = useState(0)

  return (
    <>
      <h1>  Não encontrado </h1>
      
    </>
  )
}

export default ErrorPage
