

export default function OffCanvas({content}) {

    return(
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvas" >
            <div className="offcanvas-header justify-content-end">
                {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5> */}
                <button type="button" className="btn-close " data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                {/* <ActivityContent currentActivity={currentActivity} /> */}

                {content}

            </div>
        </div>
    )
}