
import 'react-loading-skeleton/dist/skeleton.css'
import "react-datepicker/dist/react-datepicker.css";
import ReactEcharts from "echarts-for-react"; 


const BarChart = ({items, values}) => {

    const option = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow"
            }
          },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: items
        },
        grid: {containLabel: true, left: 10},
        series: [
          {
            data: values,
            type: 'bar',
            itemStyle: {
                borderRadius: 3,
                //borderWidth: 1,
                //borderType: 'solid',
                //borderColor: '#73c0de',
                //shadowColor: '#5470c6',
                //shadowBlur: 3
              }
          },
        ]
      }; 

      return (
        <ReactEcharts option={option} style={{height: '400px', width: '100%'}}
        className='echarts-bar'
        //theme='my_theme' 
        />
      );
        
}


export default function CatBar({activities, taxonomy = 'activity_category', countTime = false, title = 'Categorias'  }) {

  
  let soma = 0;
  let taxonomy_items = {};

  if(activities){                        
      activities.map( (activity, i) => {

        
        let current_taxonomy_name = activity[taxonomy]?.name;
        
        if(countTime){
          
            let activity_duration = new Date(activity.activity_registered_check_out_time) - new Date(activity.activity_registered_check_in_time);  
            
            if(taxonomy_items[current_taxonomy_name]){
              taxonomy_items[current_taxonomy_name] += activity_duration / 1000 / 60 / 60; 
            }else{
              taxonomy_items[current_taxonomy_name] = activity_duration / 1000 / 60 / 60;
            }

            if(activity_duration){
              soma += Math.fround(activity_duration);
            }else{
              console.log('NaN:', activity.post_title);
              //throw new Error('NaN:', activity.post_title);
            }

        }else{

            if(taxonomy_items[current_taxonomy_name]){
              taxonomy_items[current_taxonomy_name] += 1;
            }else{
                taxonomy_items[current_taxonomy_name] = 1;
            }
        }
        
      })

      soma = countTime ? soma / 1000 / 60 / 60 : activities.length;
  }




 


    let data = [];
    Object.keys(taxonomy_items).map( (key, i) => {       
      data.push( taxonomy_items[key].toFixed(2));
    });

    // data.sort(function(a, b){      
    //   return a - b;
    // });


    return (
      <div className="col pb-4">
        <div className="card align-items-center justify-content-center p-5">

            <h2 className="title">{Math.floor(soma).toLocaleString()}h<span className="minutes">{Math.floor( (soma % 1).toFixed(4) * 60 )}min</span></h2>
            
            <h3 className='sub-title'> {Object.keys(taxonomy_items).length} {title} </h3>

            <BarChart items={Object.keys(taxonomy_items)} values={data} width="100%" />
            
        </div>
      </div>
        
    )


}
