import { createPortal } from "react-dom";
import {format} from 'date-fns'

export default function CardActivity({activity}){

    //console.log(activity);

    function isValidDate(dateObject){
        return new Date(dateObject).toString() !== 'Invalid Date';
    }

    function calcTimeDiff(start, end){
        const time = ( new Date(end) - new Date(start) ) / 1000 / 60 / 60 ;
        return time.toFixed(2);
    }

    function formatTimeDuration(time){
        let h = Math.floor(time);
        let m = Math.floor((time % 1) * 60) ;

        return h + 'h' + m + 'min';
    }

    return(
        <div className="card bg-white p-3 mb-2">
            <div className="row">
                <div className="item col">{activity.post_title}</div>
                <div className="item col">{activity.client?.name}</div>
                <div className="item col">
                    { isValidDate(new Date(activity?.activity_registered_check_in_time)) && format(new Date(activity?.activity_registered_check_in_time), 'dd/MM/yyyy HH:mm') }
                    🕗
                    { isValidDate(new Date(activity?.activity_registered_check_out_time)) && format(new Date(activity?.activity_registered_check_out_time), 'HH:mm')}
                </div>

                <div className="item col">

                    Duração:  
                    
                    { 
                    
                        calcTimeDiff(activity?.activity_registered_check_in_time, activity?.activity_registered_check_out_time) > 3
                        ? (<b style={{'color': 'red'}}> {formatTimeDuration(calcTimeDiff(activity?.activity_registered_check_in_time, activity?.activity_registered_check_out_time) )}</b>)
                        : (<> {formatTimeDuration(calcTimeDiff(activity?.activity_registered_check_in_time, activity?.activity_registered_check_out_time) )} </>)
                    }
                    
                        
                </div>


                {/* <div className="item col">{activity?.activity_duration}</div> */}
                
                <div className="item col">{activity.activity_status?.name}</div>
                <div className="item col">{activity.activity_type?.name}</div>
                <div className="item col">{activity.activity_category?.name}</div>
                <div className="item col text-end">{activity.professional_user_obj?.display_name}</div>
            </div>

            {/* {createPortal(
                <p>This child is placed in the document body.</p>,
                document.getElementById('offcanvas-content')
            )} */}
        </div>
    )

}