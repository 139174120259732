import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid' 
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import brLocale from '@fullcalendar/core/locales/pt-br';
import useActivitiesCalendar from "./functions/useActivitiesCalendar";



import { useEffect, useState, useMemo, useCallback, useRef, createContext, useContext } from "react";



//import CardActivity from "./components/CardActivity";
import useActivities from "./functions/useActivities";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import OffCanvas from "./components/OffCanvas";
import FormActivity from "./components/FormActivity";
import ActivityContent from "./components/ActivityContent";
import useGetUsers from "./functions/useGetUsers";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

import { CacheBusterContext } from "./App";
import { useAuth } from "./AuthContext";

import Swal from "sweetalert2";


function renderEventContent(eventInfo) {
    return (
      <>
        
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
        
      </>
    )
}


  
export default function Agenda(){
    
    const cacheBusterContext = useContext(CacheBusterContext);
    const {cacheBuster, setCacheBuster} = cacheBusterContext || {};
    
    console.log('Agenda.jsx > cacheBuster', cacheBuster);
    

    //const {activitiesCalendar, updateActivitiesCalendarList, isLoading } = useActivitiesCalendar();

    const {activities, updateActivitiesList, putActivity, isLoading, activityChangeTime, forceReloadCalendar } = useActivities();
    const[offCanvasContent, setOffCanvasContent] = useState();


    const {usuarios} = useGetUsers();

    const [filterUser, setFilterUser] = useState('');

    const [calendarLoading, setCalendarLoading] = useState();



    console.log('Agenda.jsx > forceReloadCalendar', forceReloadCalendar);
    
    const { isAdmin } = useAuth();
    console.log('Agenda.jsx > isAdmin', isAdmin);


    const calendarRef = useRef(null);
    // let events = [
    //     { title: 'event 1', date: '2023-09-08' },
    //     { title: 'Evento 123', date: '2023-09-08', backgroundColor: 'red' },
    //     { title: 'event 2', date: '2023-09-09', color: 'yellow', textColor: 'black' }
    // ];


    // useEffect(()=>{
    //     console.log(filterUser);
    // }, [filterUser]);
    
    // useEffect(()=>{
    //     console.log('Agenda.jsx > useEffect > forceReloadCalendar ', forceReloadCalendar);
    // }, [cacheBuster]);
    

    function handleDateClick(info){
        console.log(info.dateStr);

        const offCanvas = new bootstrap.Offcanvas(document.getElementById('offcanvas'));
        setOffCanvasContent(<FormActivity startTime={info.dateStr} putActivity={putActivity} />)
        
        offCanvas.toggle();
    }

    const handleSelect = function(info) {
        console.log('selected ' + info.startStr + ' to ' + info.endStr);

        const offCanvas = new bootstrap.Offcanvas(document.getElementById('offcanvas'));
        setOffCanvasContent(<FormActivity startTime={info.startStr} endTime={info.endStr}  putActivity={putActivity} />)
        
        offCanvas.toggle();
    }

    const handleEventDrop = function(info) {


        console.log('handleEventDrop');
        //console.log('activitydata:', info.event.extendedProps);
        console.log('handleEventDrop > isAdmin', isAdmin);
        console.log('handleEventDrop > info', info);

        //verifica se é possível editar
        if(info.event.extendedProps.activity_registered_check_in_time && !isAdmin) { // Já foi finalizado e não é admin
            
            info.revert();

            //mostra modal avisando que somente admins podem editar
            Swal.fire({
                icon: "error",
                title: "Ação Restrita",
                text: "Esta função é exclusiva para administradores",
                //footer: '<a href="#">Why do I have this issue?</a>'
              });

            return;
        }

        if( (new Date(info.event.startStr) < new Date() )  && !isAdmin ) { //se a data é no passado

            info.revert();

            //mostra modal avisando que somente admins podem editar
            Swal.fire({
                icon: "error",
                title: "Data início no passado!",
                text: "Esta função é exclusiva para administradores",
                //footer: '<a href="#">Why do I have this issue?</a>'
              });

            return;
        }



        //pode Editar...

        let eventUpdateData = {
            ID: info.event.extendedProps.ID,
            startStr: info.event.startStr,
            endStr: info.event.endStr
        }
        
        activityChangeTime(eventUpdateData);
        
    }

    


    function handleEventClick(info){
        console.log(info.event.extendedProps);

        let calendarApi = calendarRef.current.getApi()
        // console.log('calendarApi.refetchEvents()');
        // calendarApi.refetchEvents();
        // console.log('calendarApi.refetchEvents()');

        const offCanvas = new bootstrap.Offcanvas(document.getElementById('offcanvas'));
        //setOffCanvasContent( <FormActivity updateActivitiesList={updateActivitiesList} putActivity={putActivity} />) ;
        setOffCanvasContent(<ActivityContent currentActivity={info.event.extendedProps} updateActivitiesList={updateActivitiesList} putActivity={putActivity} />)

        offCanvas.toggle();

    }


    function handleCalendarLoading(CalendarIsLoading){
            console.log('CalendarIsLoading', CalendarIsLoading);
            //setCalendarLoading(CalendarIsLoading);
    }
    

    // const handleCalendarLoading = useCallback((isLoading) => {
        
    //     setCalendarLoading(isLoading);
    //     console.log('isLoading', isLoading);
    //     console.log('calendarLoading', calendarLoading);

    //   },[isLoading]);

    
    // function handleCalendarLoading(isLoading){
    //     console.log('isLoading', isLoading);
    //     //setCalendarLoading(isLoading);
    //     //TODO: criar loading, pois usando useSTate ele fica em loop recarregando o calendario


    // }
    
    return (

        
        <div className="m-4">
            
            <div className="d-flex align-items-center justify-content-between">         
                <div className="left d-flex ">
                    <h1>Atividades</h1>

                    {calendarLoading && 
                        <div className="spinner-border m-auto ms-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }

                </div>       
                <div className="row row-cols-lg-auto g-3 align-items-center">

                    <div className="col">
                        <select
                        onChange={ e => {
                            setFilterUser(e.target.value);
                            
                            }
                        }
                        >
                            <option key="all" value=""> Todos </option>
                            {
                                usuarios.map( (user) => {
                                    return (
                                    <option key={user.id} value={user.user_login}> {user.first_name} {user.last_name} </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col">
                        <button className="btn btn-primary" 
                            data-bs-toggle="offcanvas" 
                            href="#offcanvas"
                            onClick={() => { setOffCanvasContent( <FormActivity updateActivitiesList={updateActivitiesList} putActivity={putActivity} />) } }>
                            Nova atividade 
                        </button>
                    </div>

                </div>
            </div>
            
            <div className="content ">

                            {console.log('painting')}


                            
                        {
                        useMemo(() => 
                            <FullCalendar
                            ref={calendarRef}
                            locale={brLocale}
                            plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, bootstrap5Plugin  ]}
                            themeSystem='bootstrap5'
                            dateClick={handleDateClick}
                            select={handleSelect}
                            eventClick={handleEventClick}
                            initialView="timeGridWeek"
                            slotMinTime="07:00:00"
                            slotMaxTime="24:00:00"
                            //weekends={false}
                            hiddenDays="[0]"
                            //events={events}
                            //events={activitiesCalendar}
                            events={
                                {
                                    url: 'https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity',
                                    extraParams: {
                                        cachebuster: forceReloadCalendar,
                                        professional: filterUser,
                                        //professional: 'chagas',
                                        //status:'aguardando',
                                    },

                                }
                            }
                            
                            //eventContent={renderEventContent}
                            headerToolbar={
                                {
                                    left: 'title',
                                    center: '',
                                    //right: 'dayGridMonth,dayGridWeek,dayGridDay,timeGridWeek,timeGridDay today prev,next'
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay today prev,next'
                                }
                            }
                            selectable='true'
                            editable={true}
                            eventDrop={handleEventDrop}
                            eventResize={handleEventDrop} 
                            //selectMirror='true'
                            loading={handleCalendarLoading}
                        />

                        //, [filterUser, forceReloadCalendar])
                        , [filterUser, cacheBuster])
                        
                    }
            


            </div>
       

            
            <OffCanvas content={offCanvasContent} />


            <ToastContainer />


    </div>

        
    
    )
    
}
