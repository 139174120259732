import { useContext, useEffect, useState } from "react";
import CbgImg from "./CbgImg";
import FormActivity from "./FormActivity";

import useActivities from "../functions/useActivities";

import {format} from 'date-fns';

import { CacheBusterContext } from "../App";


import Swal from "sweetalert2";


import { useAuth } from "../AuthContext";


import {
    QueryClient,
    QueryClientProvider,
    useQuery,
  } from '@tanstack/react-query'


// Crie uma instância de QueryClient fora do componente para que não seja recriada em cada renderização.
const queryClientImages = new QueryClient();



export default function ActivityContent({currentActivity, updateActivitiesList, putActivity}){


    




    

    //const {cacheBuster, setCacheBuster} = useContext(CacheBusterContext || null);

    const cacheBusterContext = useContext(CacheBusterContext);
    const {cacheBuster, setCacheBuster} = cacheBusterContext || {};



    //const cacheBuster = useContext(CacheBusterContext);

    //return (<></>);
    console.log('logging currentActivity', currentActivity);
    console.log('cacheBuster', cacheBuster);
    // setCacheBuster('allalal');
    // console.log('cacheBuster', cacheBuster);

    const {deleteActivity, isLoading, forceReloadCalendar} = useActivities();

    const [editing, setEditing] = useState(false);
    const [editPossible, setEditPossible] = useState(true);
    
    
    //const [isAdmin___, setIsAdmin___] = useState(false);
    const { isAdmin } = useAuth();

    console.log('logging editing', editing);
    

    useEffect( () => {
        console.log('in');
        setEditing(false);


        
        return () => { console.log('out'); }
    },[currentActivity]);


    //https://maps.googleapis.com/maps/api/staticmap?center=Williamsburg,Brooklyn,NY&zoom=13&size=600x300&markers=color:blue|label:S|11211|11206|11222&key=AIzaSyCM6tn81sk2GNE9HS3HFgK2g4U7k4_x6-0');

    //let map_style= 'maptype=roadmap&style=feature:poi|visibility:off&style=feature:road|visibility:simplified&style=feature:road|element:labels.text.fill|color:0xa1a1a1&style=feature:transit|visibility:off';

    let map_style= 'maptype=roadmap&style=feature:poi|element:labels.icon|saturation:-100|lightness:10&style=feature:poi|element:labels.text.fill|color:0x9c9c9c&style=feature:poi|element:labels.text.stroke|color:0xffffff&style=feature:transit|visibility:off';
    
    let location_image_url = encodeURI('https://maps.googleapis.com/maps/api/staticmap?size=600x300&markers=color:0xffc107|label:O|' + currentActivity?.activity_checkout_location + '&markers=color:0x6610f2|label:I|' + currentActivity?.activity_checkin_location +'&key=AIzaSyCM6tn81sk2GNE9HS3HFgK2g4U7k4_x6-0&' + map_style);
    let location_url = encodeURI('https://www.google.com/maps/dir/?api=1&origin=' + currentActivity?.activity_checkin_location + '&destination=' + currentActivity?.activity_checkout_location );




    
  function handleDeleteSubmit(e){
    e.preventDefault();

        console.log('onClick button Deletar');

        Swal.fire({
            title: "Tem certeza?",
            text: "Não será possível recuperar esta atividade!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Sim, pode deletar!",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                
                (async () => {
                    await deleteActivity(currentActivity?.ID);
                    setCacheBuster( new Date().valueOf() );
                    bootstrap.Offcanvas.getInstance('#offcanvas').hide();
                })()


            }
        });

        
        
    

  }




    function isValidDate(dateObject){
        return new Date(dateObject).toString() !== 'Invalid Date';
    }
    

    function ActivityViewContent(){

        //console.log('currentActivity', currentActivity);

        //carrega objeto activity completo (com imagens)
        const fetchImages = async () => {
            const res = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity?id="+currentActivity?.ID);
            return res.json();
        };
        const { isLoading, error, data } = useQuery({
            queryKey: ['images'],
            queryFn: fetchImages
        });        
        const currentActivityWithImages = data;

        
         
        //verifica permissões para edição
        if (currentActivity?.activity_registered_check_in_time && !isAdmin){            
            setEditPossible(false);            
        }else{
            setEditPossible(true);
        }

        console.log('currentActivity?.activity_registered_check_in_time', currentActivity?.activity_registered_check_in_time);
        console.log('isAdmin',isAdmin);
        console.log('EditPossible = ', editPossible);

        return(

            

                <div className="row">
                    
                    <div className="item "> <h2> {currentActivity?.post_title}</h2></div>
                    <div className="item row"> 
                        <div className="col">
                            <b>Cliente:</b> {currentActivity?.client?.name}
                        </div>
                        <div className="col">
                            <b>Profissional:</b> {currentActivity?.professional_user_obj?.display_name}                
                        </div>
                    </div>

                    <div className="item row">
                        <div className="col">
                            <b>Início previsto: </b>
                            { isValidDate(new Date(currentActivity?.activity_check_in_time)) && format(new Date(currentActivity?.activity_check_in_time), 'dd/MM/yyyy HH:mm') }                        
                        </div>
                        <div className="col">
                            <b>Início realizado: </b>
                            { isValidDate(new Date(currentActivity?.activity_registered_check_in_time)) && format(new Date(currentActivity?.activity_registered_check_in_time), 'dd/MM/yyyy HH:mm') }    
                        </div>
                        
                    </div>
                    <div className="item row">
                        <div className="col">
                            <b>Fim previsto: </b>
                            { isValidDate(new Date(currentActivity?.activity_check_out_time)) && format(new Date(currentActivity?.activity_check_out_time), 'dd/MM/yyyy HH:mm')  }                         
                        </div>
                        <div className="col">
                            <b>Fim realizado: </b>                    
                            { isValidDate(new Date(currentActivity?.activity_registered_check_out_time)) && format(new Date(currentActivity?.activity_registered_check_out_time), 'dd/MM/yyyy HH:mm') }                
                        </div>
                        
                    </div>


                    <div className="item row">
                        <div className="col">
                            <b>Duração prevista: </b> {currentActivity?.activity_duration}
                            {/* <b>Post_Date: </b> {currentActivity?.post_date} */}
                        </div>
                        <div className="col">
                            <b>Duração realizada: </b> {currentActivity?.activity_duration}
                        </div>
                    </div>

                    <div className="item row">
                        <div className="col">
                            <b>Status:</b> {currentActivity?.activity_status?.name}
                        </div>
                        <div className="col">
                            <b>Tipo:</b> {currentActivity?.activity_type?.name}                        
                        </div>
                        
                        
                    </div>

                    <div className="item row">
                        <div className="col">
                            <b>Duração:</b> {currentActivity?.activity_duration}
                        </div>    
                    </div>

                    <div className="item row">                    
                        <div className="col">
                            <b>Obs:</b> {currentActivity?.activity_obs}                        
                        </div>                    
                    </div>
                    
                    { 
                    // só mostra imagens se for uma tarefa já realizada

                    ( currentActivity?.activity_registered_check_in_time ) && 
                    
                        <>

                            <hr />

                            <h4> Imagens </h4>
                            <div className="column-gap-2 d-flex imagens mb-4">
                                {/* <CbgImg title={'Foto Checkin'} src={currentActivity?.activity_checkin_photo?.imgData}/>                    
                                <CbgImg title={'Foto Cliente'} src={currentActivity?.activity_checkout_photo_client?.imgData}/>                         
                                <CbgImg title={'Ata Reunião'} src={currentActivity?.activity_checkout_photo_minute?.imgData} />    */}
                                <CbgImg title={'Foto Checkin'} src={currentActivityWithImages?.activity_checkin_photo?.imgData}/>                    
                                <CbgImg title={'Foto Cliente'} src={currentActivityWithImages?.activity_checkout_photo_client?.imgData}/>                         
                                <CbgImg title={'Ata Reunião'} src={currentActivityWithImages?.activity_checkout_photo_minute?.imgData} />   
                            </div>

                            
                            <a href={location_url} target="_blank"> <img src={location_image_url} alt="" /> </a>
                        
                        </>  
                    }

                </div>
        )
    }
    return(

        <QueryClientProvider client={queryClientImages}>

            <div className="d-flex justify-content-end mb-3">
                <div className="btn-group btn-group-sm" role="group" aria-label="Small button group">


                    <button type="button" className="btn btn-outline-primary"
                        onClick={ () => { setEditing( !editing ) }} 
                        disabled={!editPossible}
                         > 
                        { editing ? 'Voltar' : 'Editar' }

                        

                    </button>


                    <button type="button" className="btn btn-outline-danger"
                        onClick={handleDeleteSubmit}
                        disabled={isLoading || !editPossible}
                    > Deletar </button>
                </div>            
            </div>
            
            
            <div className="card bg-white p-3 mb-2">

                
                { 
                    editing ? 
                    <FormActivity updateActivitiesList={updateActivitiesList} putActivity={putActivity} activityData={currentActivity} /> : 
                    <ActivityViewContent />
                }
                
                
            </div>

        </QueryClientProvider>
    )

}