

export default function CbgImg({src, title }){

    return (

        <div className="cbg-img">
            <div className="cbg-img-container">
                { src && <img src={src} alt={title} /> }
                { !src && <span>{title}</span> }
            </div>
            { src && <span className="title">{title}</span> }
        </div>

    )

}