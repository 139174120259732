
  import { Link } from 'react-router-dom';
  
  import { useState, useEffect } from "react";
  
  import { useNavigate } from 'react-router-dom';
  import { useAuth } from './AuthContext';

  const Login = () => {
  
  
    const history = useNavigate();
  
    const { 
      isAuthed, 
      doLogin, 
      //login,
      isSubmitted,
      isValidating,
      isError,
      errorMessage 
    } = useAuth();  
  
  
    const authUser = (username, password) =>{
  
        setIsValidating(true);
  
        console.log(username, password);
  
    }
  
    const handleSubmit = (event) => {
        
        event.preventDefault();
  
        let data = new FormData(event.target);
        let formObject = Object.fromEntries(data.entries());
        let { username, password } = formObject;
        
        //authUser(username, password);
        doLogin({
          username: username,
          password: password,
        });
  
    };      
   
  
    // async function handleLogin() {
    //   await Login({
    //     username: 'rafaelcodomingues@gmail.com',
    //     password: '123456',
    //   });
    // }
  
    // const handleLogin = async () => {
    //   let result = await Login({
    //     username: 'anderson',
    //     password: '!C88a70a20!',
    //   });
      
    //   console.log('result: ' + result);
  
    //   if(result){
    //     console.log('resolve true');
    //   }else{
    //     console.log('resolve FALSE');
    //   }
    // }
  
  
  return (
    
        <div className="login-container">
  
          
          <div className="logo">
  
            {/* <img src="src/assets/logo-catalyst-bg.svg" alt="Logo CatalystBG" />
            <IonImg src="src/assets/logo-catalyst-bg.svg" alt="Logo CatalystBG"></IonImg> */}
  
            
          </div>
          
          <div className="login-form">
              
              <div className="welcome-message">
                <h1 className='title'>Seja bem-vindo </h1>
                <div className='message'> Resultados incríveis estão logo à frente </div>
              </div>
              
              <div className="form">
                <form onSubmit={handleSubmit}>
                  <div className="input-container">
                    <label>Username </label>
                    <input type="text" name="username" required autoComplete="off"/>
                  </div>
  
                  <div className="input-container">
                    <label>Senha </label>
                    <input type="password" name="password" required />
                  </div>
  
                  <div className='info'>                  
                    { (isError) ? <div> {errorMessage} </div> : ''}
                    <div className="progress-bar">
                      {isValidating 
                      ? 
                      <div className="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width:'100%'}}></div>
                        </div>
                      : ''
                      }
                    </div>
                    
                  </div>
                  
                  <div className="button-container">
                    <button type="submit"> Login </button>
                  </div>
                </form>
                
              </div>
  
          </div>
  
        </div>
  
  );
  };
  
  export default Login;
  