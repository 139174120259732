import { Link, NavLink } from "react-router-dom";
import { useAuth } from "./AuthContext";


export default function Sidebar() {

  const { Logout, isAdmin } = useAuth();

  console.log('Sidebar.jsx > isAdmin', isAdmin);

  //const storagedUser = JSON.parse(localStorage.getItem('user'));
  //const _isAdmin = /anderson|erick|julio/.test(storagedUser.user_login) ?  true : false; 
  // console.log('storagedUser', storagedUser);
  // console.log('_isAdmin', _isAdmin);

  return (
    <>


<div className="d-flex flex-column p-3 text-white bg-dark vh-100">
    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
      <span className="fs-4">CatalystBG</span>
    </a>
    <hr/>
    <ul className="nav nav-pills flex-column mb-auto">
      

      {/* <li>
        <NavLink to={'/'} className="nav-link text-white ">    
          <i className="bi-speedometer2 me-2"></i>
          Dashboard
        </NavLink>
      </li> */}

      <li>
        <NavLink to={'/'} className="nav-link text-white ">    
          <i className="bi-calendar-week me-2"></i>
          Agenda
        </NavLink>
      </li>


      {
        isAdmin &&


        (
          <>

          <li>
            <NavLink to={'relatorios'} className="nav-link text-white ">    
              <i className="bi-file-earmark-bar-graph me-2"></i>
              Relatórios
            </NavLink>
          </li>


          <li>
            <NavLink to={'usuarios'} className="nav-link text-white ">    
              <i className="bi-people-fill me-2"></i>
              Usuários
            </NavLink>
          </li>



          <li>
            <NavLink to={'empresas'} className="nav-link text-white ">    
              <i className="bi-buildings me-2"></i>
              Empresas
            </NavLink>
          </li>

          <li>
            <NavLink to={'categorias'} className="nav-link text-white ">    
              <i className="bi-tags me-2"></i>
              Categorias
            </NavLink>
          </li>

          <li>
            <NavLink to={'atividades'} className="nav-link text-white ">    
              <i className="bi-briefcase me-2"></i>
              Atividades
            </NavLink>
          </li>

        </>
        
        )
      }

      

    </ul>
    <hr/>



    <ul className="nav flex-column">
      

      <li>
        <a href={`?${Math.floor(Math.random() * 100000)}`} className="nav-link text-white ">    
          <i className="bi-arrow-clockwise me-2"></i>
          Atualizar
        </a>
      </li>

      <li>
        <a href="#" onClick={Logout} className="nav-link text-white ">    
          <i className="bi-box-arrow-left me-2"></i>
          Sair
        </a>
      </li>

    </ul>
{/*       
    <div className="dropdown">
      <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
        <strong>mdo</strong>
      </a>
      <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
        <li><a className="dropdown-item" href="#">New project...</a></li>
        <li><a className="dropdown-item" href="#">Settings</a></li>
        <li><a className="dropdown-item" href="#">Profile</a></li>
        <li><hr className="dropdown-divider" /></li>
        <li><a className="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div> */}

</div>



    </>
  );
}
