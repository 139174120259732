import { useState, createContext, useContext } from 'react'

import { Link, Outlet, Navigate } from 'react-router-dom'
import Sidebar from './Sidebar'

import { useAuth } from './AuthContext';



  
export const CacheBusterContext = createContext(null);


  
function App() {
  

  
  const [cacheBuster, setCacheBuster] = useState( new Date().valueOf() );


  
  const { isAuthed, isAdmin, user } = useAuth();  
    
  console.log('App.jsx isAuthed', isAuthed);
  console.log('App.jsx isAdmin', isAdmin);
  console.log('App.jsx user', user);

  return (
    <>
     


      <div className="container-fluid">
        <div className="row">
          
          <div className="col-md-3 col-lg-2 gx-0">

            { isAuthed && <Sidebar /> }

          </div>

          <div className="col-md-9 col-lg-10 gx-0 main-content">
           
            <CacheBusterContext.Provider value={{cacheBuster, setCacheBuster}}>

                      <Outlet />
            
            </CacheBusterContext.Provider>

          </div>

        </div>
      </div>
          

    </>
  )
}

export default App
