import { useEffect, useId, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import useTerms from "./functions/useTerms";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function Empresas() {

  const [response, setResponse] = useState("");
  const formID = useId();
  
  const {empresas} = useTerms();

  const ListEmpresas = () => {

    // useEffect( () => {
    //     setUsuarios( getUsers() );
    //   }, []);
    

      
    return (
      <>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2 className="h3">Empresas</h2>
        </div>

        <NavLink to='new' className="btn btn-outline-secondary mb-4">    
                    <i className="bi-person-plus-fill me-2"></i>
                    Adicionar novo
        </NavLink>
        
        {empresas.length == 0 && <Skeleton count={5.8} height={30} baseColor={'var(--bs-secondary-bg-subtle)'} highlightColor={'var(--bs-tertiary-bg)'} /> }

        <ul className="nav nav-pills mb-auto mw-100 overflow-y-auto">

        {empresas.map((empresa) => {

          return (
                
                <li key={empresa.term_id} className=" w-100">
                    <NavLink to={empresa.slug} state={empresa} className="nav-link text-body-secondary">    
                        {empresa.name}  
                    </NavLink>
                </li>
          ) 


        })}

        </ul>
      </>
    );
  };



  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-3 bg-body-secondary d-flex flex-column vh-100 ">

            <ListEmpresas />

          </div>

          <div className="col-9">
            

              <Outlet context={'client'} />

          </div>
        </div>
      </div>
    </>
  );
}
