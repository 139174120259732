import { useEffect, useId, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
//import { getUsers } from "./functions/useGetUsers";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import useGetUsers from "./functions/useGetUsers";

export default function Usuarios() {
  //const [usuarios, setUsuarios] = useState([]);
  const [response, setResponse] = useState("");

  const formID = useId();
  //const usuarios = getUsers();



  
  const Users = () => {


    const {usuarios} = useGetUsers();
      
    return (
      <>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2 className="h3">Usuários</h2>
        </div>

        <NavLink to='new' className="btn btn-outline-secondary mb-4">    
                    <i className="bi-person-plus-fill me-2"></i>
                    Adicionar novo
        </NavLink>

        {usuarios.length == 0 && <Skeleton count={5.8} height={30} baseColor={'var(--bs-secondary-bg-subtle)'} highlightColor={'var(--bs-tertiary-bg)'} /> }

        <ul className="nav nav-pills mb-auto mw-100 overflow-y-auto">

        {usuarios.map((user) => {

          return (
                
                <li key={user.id}  className="w-100">
                    <NavLink to={user.user_login} state={user}  className="nav-link text-body-secondary">    
                    {/* <i className="bi-speedometer2 me-2"></i> */}
                    {user.first_name} {user.last_name}  
                    </NavLink>
                </li>
          ) 


        })}

        </ul>
      </>
    );
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-3 bg-body-secondary d-flex flex-column vh-100 ">
            <Users />
          </div>

          <div className="col-9">
            

              <Outlet key={Date.now()} />

          </div>
        </div>
      </div>
    </>
  );
}
