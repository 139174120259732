import { useState, useEffect } from "react";

function useGetUsers(){




  const [usuarios, setUsuarios] = useState([]);

    async function getUsers(userID='') {
        
      let token = btoa("adminbg" + ":" + "Fj55t5rhKKg%%M3bQ5jOmf");
      const headers = {
        Authorization: "Basic " + token,
      };


        fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/users" + userID, {        
          method: "GET",
          headers: headers,
        })
          .then((response) => {
            console.log(response);
            response.json().then((data) => {
              console.log("A solicitação USERS foi feita.");
              console.log(data);

              setUsuarios(data);
            });
          })
          .catch((err) => {
            console.log("Houve um Erro na USERS.");
            console.log(err.message);

            setUsuarios([]);
          });
      

    }


    useEffect(() => {

      getUsers();
      
    }, []);



  return {usuarios};

}


export default useGetUsers;