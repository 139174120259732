import { useEffect, useState, useContext } from "react";
import CardActivity from "./components/CardActivity";
import useActivities from "./functions/useActivities";
import ActivityContent from "./components/ActivityContent";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import OffCanvas from "./components/OffCanvas";
import FormActivity from "./components/FormActivity";

import { Typeahead } from "react-bootstrap-typeahead";

import useTerms from "./functions/useTerms";
import useGetUsers from "./functions/useGetUsers";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import ReactApexCharts from 'react-apexcharts'
//import Chart from "react-apexcharts";

import ReactEcharts from "echarts-for-react"; 

import {
    QueryClient,
    QueryClientProvider,
    useQuery,
  } from '@tanstack/react-query'


import CatBar from "./components/charts/CatBar";
import CatPie from "./components/charts/CatPie";
  
const queryClient = new QueryClient()

const formID = '';

export default function Relatorios() {
    return (
        <QueryClientProvider client={queryClient}>
            <Report />
        </QueryClientProvider>
    )
}




import { CacheBusterContext } from "./App";





function Report(){


    const cacheBusterContext = useContext(CacheBusterContext);
    const {cacheBuster, setCacheBuster} = cacheBusterContext || {};
    

    const {usuarios} = useGetUsers();
    const {empresas, categorias, activityStatus} = useTerms();

    const [optionsClient, setOptionsClient] = useState(['...']);
    const [optionsProfessional, setOptionsProfessional] = useState(['...']);
    const [optionsStatus, setOptionsStatus] = useState(['...']);
    const [optionsCategory, setOptionsCategory] = useState(['...']);
    

    //const {activities, updateActivitiesList, putActivity, isLoading } = useActivities();
    const {putActivity } = useActivities();
    const [offCanvasContent, setOffCanvasContent] = useState();
    
    const [realStart, setRealStart] = useState(new Date() - (7 * 24 * 60 * 60 * 1000 ));
    const [realEnd, setRealEnd] = useState(new Date());    
    
    const [client, setClient] = useState('');
    const [professional, setProfessional] = useState('');

    const [usersColors, setUsersColors] = useState('');


    
    const [isEdited, setIsEdited] = useState('');


    // useEffect( () => {
    //     // const offCanvas = document.getElementById('offcanvas')
    //     //     offCanvas.addEventListener('show.bs.offcanvas', loadModalContent)

    //     // return () => { offCanvas.removeEventListener('show.bs.offcanvas', loadModalContent) };

    //     console.log('horasTrabalhadas', horasTrabalhadas(activities));

    // },[activities]);

    useEffect( () => {
        

        

        const profesionalNames = Array();
        const clientNames = Array();
        const categoryNames = Array();
        const statusNames = Array();

        const userColorsArray = Array();

        
        usuarios.map( (user) => {
            profesionalNames.push(user.user_login);      
        });

        usuarios.map( (user) => {
            userColorsArray[user.user_login] = user.color;
        });
      
        console.log('usersColors', usersColors);
        
        empresas.map( (client) => {
            //console.log(client);
            if(client.situacao == 'inativa') return;
            clientNames.push(client.name);      
        });
    
        categorias.map( (category) => {
            categoryNames.push(category.name);      
        });
          
        activityStatus.map( (activity_status) => {
            console.log('activity_status.name:', activity_status.name)
            statusNames.push(activity_status.name);      
        });
          
      
        setOptionsProfessional(profesionalNames);
        setOptionsClient(clientNames);
        setOptionsCategory(categoryNames);
        setOptionsStatus(statusNames);
        
        setUsersColors(userColorsArray);

    }, [usuarios, empresas, categorias]);
    
    
    

    const { isPending, isFetching, error, data, refetch } = useQuery({
        queryKey: ['activities', realStart, realEnd, client, professional, isEdited, cacheBuster],
        //queryFn: () => fetchTodoById(todoId),
        queryFn: () =>
            fetch(
                'https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity?start=' 
                + new Date(realStart).toISOString() 
                +'&end='+ new Date(realEnd).toISOString() 
                +'&client=' + client 
                + '&professional=' + professional 
                + '&flags=' + isEdited ).then(
            (res) => res.json(),
            ),
                    
        // queryFn: async () => {
        //     const response = await fetch('/todos/' + todoId)
        //     if (!response.ok) {
        //     throw new Error('Network response was not ok')
        //     }
        //     return response.json()
        // },
    })


    if (error) return 'An error has occurred: ' + error.message





    return (
        <div className="m-4">
            <div className="container graficos">
                <div className="row row-cols-3 row-cols-sm-6">                
            
            
                    <div className="col mb-3">          
                        <label className="form-label" htmlFor={formID + 'client'} > Cliente </label>    
                        <Typeahead
                        clearButton="true"
                        options={optionsClient}
                        id={formID + 'client'}
                        emptyLabel="Nenhum item encontrado"
                        inputProps={{name:'client', id:formID + 'client'}}
                        //defaultInputValue="todos"
                        onChange={(val) => setClient(val[0] || '')}
                        
                        />
                    </div>
                    <div className="col mb-3">          
                    <label className="form-label" htmlFor={formID + 'professional'} > Profissional </label>    
                    <Typeahead
                        clearButton="true"
                        options={optionsProfessional}
                        id={formID + 'professional'}
                        emptyLabel="Nenhum item encontrado"
                        inputProps={{name:'professional', id:formID + 'professional'}}
                        //defaultInputValue="Todos"
                        onChange={(val) => {

                            setProfessional(val[0] || '');
                            console.log('val', val[0])
                            }
                        }
                    />          
                    </div>
                
                                
                    <div className="col mb-3">          
                        <label className="form-label" htmlFor={formID + 'activity_check_in_time'} > De </label>   
                        <DatePicker
                            selected={realStart}
                            onChange={(date) => setRealStart(date)}
                            //showTimeSelect
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            //name="activity_check_in_time"
                            //id={formID + 'activity_check_in_time'}
                            autoComplete="off"
                            //placeholderText="asfad"
                        />
                    </div>
                    

                    <div className="col mb-3">          
                        <label className="form-label" htmlFor={formID + 'activity_check_out_time'} > Até </label>   
                        <DatePicker
                            selected={realEnd}
                            onChange={(date) => setRealEnd(date)}
                            //showTimeSelect
                            timeIntervals={15}
                            dateFormat="dd/MM/yyyy"
                            className="form-control"
                            //name="activity_check_in_time"
                            //id={formID + 'activity_check_in_time'}
                            autoComplete="off"
                            //placeholderText="asfad"
                        />
                    </div>
            
                    <div className="col mb-3">          
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id={formID + 'is_edited'}
                                //onChange={(date) => setIsEdited} 
                                onChange={(event) => {

                                    console.log('val', event.target.checked);                                
                                    setIsEdited(event.target.checked ? 'editado' : '');
                                    
                                    }
                                }
                            
                            />
                            <label className="form-check-label" htmlFor={formID + 'is_edited'} > Editado </label>   
                        </div>                        
                    </div>

                </div>
            </div>
            
            <div className="content report ">


                        
                        {/* //if (isPending) return 'Loading...'; */}
                        {isPending && <Skeleton count={10} height={50}  /> }
                        {isFetching && <Skeleton count={10} height={50}  /> }


                        {/* 
                        <button className="btn btn-primary" 
                        onClick={ () => {refetch()} }>                         
                        Nova atividade </button> */}

                        <div className="container">
                            <div className="row row-cols-1 row-cols-sm-2">
                            
                                
                                { !!data && <CatPie activities={data} taxonomy="activity_category" countTime={false} title='Categorias' /> }
                                
                                { !!data && !client && <CatPie activities={data} taxonomy="client" countTime='true' title='Empresas'/> }
                                
                                { !!data && !professional && <CatPie activities={data} taxonomy="professional_id" countTime='true' title='Profissionais' colorPallete={usersColors} /> }
                                
                                { !!data && <CatBar activities={data} taxonomy="activity_category" countTime={true} title='Categorias' /> }
                                
                            </div>
                        </div>
                        

                        {data && data.map( (activity) => {

                            return (

                                <a key={activity.ID} 
                                    className="text-decoration-none" 
                                    data-bs-toggle="offcanvas" 
                                    href="#offcanvas" 
                                    onClick={() => {
                                        setOffCanvasContent(<ActivityContent currentActivity={activity} 
                                            //updateActivitiesList={updateActivitiesList} 
                                            putActivity={putActivity} 
                                            />)}
                                    }
                                >

                                    <CardActivity activity={activity}  />
                                </a>
                            )
                        })}


                


                


                


            </div>
       

            
            <OffCanvas content={offCanvasContent} />



    </div>
    )
}